














































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { ParkingEntranceOrExitEntityModel, ParkingEntranceOrExitQueryModel } from './model/parking-entrance-or-exit-entity';
import ParkingEntranceOrExitService from './service/parking-entrance-or-exit';
import ParkingEntranceOrExitDialog from './parking-entrance-or-exit-dialog.vue';
import ParkingEntranceOrExitDrawer from './parking-entrance-or-exit-drawer.vue';
import { ViewModeType } from '@common-src/model/enum';

@Component({
    components: {
        'parking-entrance-or-exit-drawer': ParkingEntranceOrExitDrawer,
        'parking-entrance-or-exit-dialog': ParkingEntranceOrExitDialog
    }
})
export default class ParkingEntranceOrExitListComponent extends TableDialogFormComponent<ParkingEntranceOrExitEntityModel, ParkingEntranceOrExitQueryModel> {
    ParkingEntranceOrExitEntityModel = ParkingEntranceOrExitEntityModel;
    async created() {
        this.initTable({
            service: ParkingEntranceOrExitService,
            queryModel: new ParkingEntranceOrExitQueryModel(),
            tableColumns: ParkingEntranceOrExitEntityModel.getTableColumns()
        });
        this.getList();
    }

    viewClick(model: ParkingEntranceOrExitEntityModel) {
        (this.$refs.parkingDetailDrawer as ParkingEntranceOrExitDrawer).drawerViewOpen(model.id, model.name);
    }

    addClick(model: ParkingEntranceOrExitEntityModel): void {
        (this.formDialog as ParkingEntranceOrExitDialog).vehicleDialogOpen(model, this.service, ViewModeType.NEW);
    }

    editClick(model, needGetDetail: boolean = false): void {
        (this.formDialog as ParkingEntranceOrExitDialog).vehicleDialogOpen(model, this.service, ViewModeType.UPDATE, needGetDetail);
    }
}

