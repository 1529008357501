var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        placement: _vm.placement,
        width: _vm.width,
        closable: _vm.closable,
        visible: _vm.drawerVisible,
        "destroy-on-close": true,
      },
      on: { close: _vm.drawerClose },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v(_vm._s(_vm.drawerTitle)),
      ]),
      _c(
        "jtl-card",
        { attrs: { title: "基本信息" } },
        [
          _c(
            "a-descriptions",
            { attrs: { bordered: "" } },
            _vm._l(_vm.viewModel.basic, function (item, itemIndex) {
              return _c(
                "a-descriptions-item",
                { key: itemIndex, attrs: { label: item.name } },
                [
                  item.name === "创建时间"
                    ? [
                        _vm._v(
                          " " + _vm._s(_vm._f("dateFormat")(item.value)) + " "
                        ),
                      ]
                    : [
                        _vm._.isArray(item.value)
                          ? _vm._l(item.value, function (vItem) {
                              return _c("a-tag", { key: vItem }, [
                                _vm._v(" " + _vm._s(vItem) + " "),
                              ])
                            })
                          : [_vm._v(" " + _vm._s(item.value) + " ")],
                      ],
                ],
                2
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("br"),
      _vm._l(_vm.viewModel.attrs, function (item, itemIndex) {
        return _c(
          "jtl-card",
          { key: itemIndex, attrs: { title: item.facilityName } },
          [
            _c("a-table", {
              attrs: {
                loading: _vm.listLoading,
                columns: _vm.otherColumns,
                "data-source": item.vals,
                pagination: false,
                scroll: _vm.tableScroll,
                size: _vm.TableSize,
              },
              on: { change: _vm.tableChange },
            }),
          ],
          1
        )
      }),
      _c("br"),
      _c(
        "jtl-card",
        { attrs: { title: "视频监控" } },
        [
          _c(
            "a-table",
            {
              attrs: {
                loading: _vm.listLoading,
                columns: _vm.cameraColumns,
                "data-source": _vm.viewModel.cameraList,
                pagination: false,
                scroll: _vm.tableScroll,
                size: _vm.TableSize,
              },
              on: { change: _vm.tableChange },
              scopedSlots: _vm._u([
                {
                  key: "val",
                  fn: function (text) {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm._f("entityStatusFilter")(text)) + " "
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "action" },
                [_c("a-icon", { attrs: { type: "video-camera" } })],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }